<template>
  <div class="page" :loading="loading" :loaded="Boolean(events)">
    <section class="top-section">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <h1>Events</h1>
            <h3>Find events of relevance to you</h3>
          </div>
        </div>
      </div>
    </section>

    <div class="events" v-if="events">
      <div class="wrapper">
        <div class="event-container" v-for="event in events" :key="event.id">
          <EventItem :event="event" />
        </div>
      </div>
    </div>
    <div class="loading">
      <div class="loader"></div>
      <div>Loading more events...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateFormat from "./DateFormat.vue";
import BpsImage from "./BpsImage.vue";
import EventItem from "./EventItem.vue";

export default {
  name: "Events",
  components: {
    DateFormat,
    BpsImage,
    EventItem,
  },
  data() {
    return {
      events: null,
      offset: 0,
      loading: false,
      endReached: false,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    offset() {
      this.getItems();
    },
    testMode() {
      this.getItems();
    },
  },
  methods: {
    loadEvents() {
      const container = this.$el.parentNode;
      if (
        container &&
        !this.loading &&
        !this.endReached &&
        this.scrollValue !== window.scrollY &&
        container.clientHeight - window.innerHeight <= window.scrollY
      ) {
        this.loading = true;
        this.offset += window.innerWidth > 1280 ? 6 : 4;
      }
      if (container) this.scrollValue = window.scrollY;
    },
    getItems() {
      this.loading = true;
      if (this.eventsRef) this.eventsRef();
      this.eventsRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}events`)
        .orderBy("start", "desc")
        .limit(this.offset)
        .onSnapshot((events) => {
          this.events = events.docs.map((doc) => doc.data());
          this.endReached = this.events.length < this.offset;
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
    },
  },
  mounted: function() {
    window.addEventListener("scroll", this.loadEvents.bind(this), false);
    this.offset = window.innerWidth > 1280 ? 6 : 4;
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", this.loadEvents.bind(this), false);
    if (this.eventsRef) this.eventsRef();
  },
};
</script>

<style scoped>
.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../assets/images/wb_bg_invest.jpg);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 26.56%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 56px 96px;
  border-radius: 12px;
  background: linear-gradient(36deg, rgba(14, 58, 90, 0.80) 13.82%, rgba(14, 58, 90, 0.50) 32.99%, rgba(14, 58, 90, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1 {
  font-size: 8rem;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 4rem;
  line-height: 1.1625;
  max-width: 900px;
}
.top-section .container .content p {
  font-size: 1.125rem;
}

.events .wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 48px 32px;
}

.event-container {
  width: calc(33.33333333% - 22px);
}

.event-container[large] {
  width: 100%;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  opacity: 0;
}

.page[loading] .loading {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  .event-container {
    width: calc(50% - 16px);
  }
}

@media screen and (max-width: 1024px) {
  .page-header {
    margin-top: 0;
    padding: 64px;
  }
}

@media screen and (max-width: 850px) {
  .event-container {
    width: 100%;
    margin: 0 0 24px;
    padding: 0;
  }
}
</style>
